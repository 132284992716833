import PropTypes from 'prop-types';
import React from 'react';

import { trackMenuItemClick } from '@/helpers/dataLayer';

import { LinkWrapper } from './Link.style';

const Link = ({
  href,
  title,
  className,
  closeNavigation,
  'data-cy': dataCy,
}) => {
  return (
    <LinkWrapper
      href={href}
      data-cy={dataCy || `mobile nav: ${title}`}
      onClick={() => {
        closeNavigation();
        trackMenuItemClick();
      }}
      className={className}>
      {title}
    </LinkWrapper>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  'data-cy': PropTypes.string,
  closeNavigation: PropTypes.func.isRequired,
};

export default Link;
