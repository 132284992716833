import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import React from 'react';

import { trackMenuItemClick } from '@/helpers/dataLayer';

import Link from '../Link/Link';
import { LinkWrapper } from './MenuItem.style';

const MenuItem = ({ item, closeNavigation, onClick }) => {
  const {
    id,
    attributes: { link: href, title },
  } = item;

  const children = item.attributes.navbar_links?.data;

  if (children?.length) {
    return (
      <LinkWrapper
        key={id}
        onClick={() => {
          onClick?.(item);
          trackMenuItemClick();
        }}
        data-cy={`mobile nav: ${title}`}>
        {title}
        <ChevronRightIcon />
      </LinkWrapper>
    );
  } else if (href) {
    return <Link href={href} title={title} closeNavigation={closeNavigation} />;
  }
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  closeNavigation: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default MenuItem;
