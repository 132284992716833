import { styled } from '@mui/material/styles';
import Link from 'next/link';

export const ImageWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 80,
  position: 'relative',
  img: {
    borderRadius: 4,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  marginBottom: theme.spacing(1),
}));

export const CategoryLink = styled('span', {
  shouldForwardProp: prop => prop !== 'hasSublinks',
})(({ theme, hasSublinks }) => ({
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightSemiBold,
  letterSpacing: '0.32px',
  display: 'block',
  lineHeight: 1.7,
  color: theme.palette.primary.main,
  '&:hover, &:active': {
    color: theme.palette.primary.dark,
  },
  ...(hasSublinks && { marginBottom: theme.spacing(1.5) }),
}));

export const SubLink = styled(Link)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightRegular,
  letterSpacing: '0.32px',
  lineHeight: 1.17,
  color: theme.palette.text.secondary,
  '&:hover, &:active': {
    color: theme.palette.text.primary,
  },
}));
